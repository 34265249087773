<template>
  <div>
    <layout-vertical>
      <router-view />

      <template #navbar="{ toggleVerticalMenuActive }">
        <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
        <!-- <b-alert variant="primary" show>
      <div class="alert-body">
        <span><strong>Good Morning!</strong> Start your day with some alerts.</span>
      </div>
    </b-alert> -->
      </template>

      <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->
    </layout-vertical>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
// import { $themeConfig } from '@themeConfig'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    // AppCustomizer,
    LayoutVertical,
    Navbar,
    BAlert,
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
